let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-newcrest-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-newcrest-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://s2nn6tsffva6thxnmldiok4rcm.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://0btasdapug.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://9pn7h5qjva.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.newcrest.forwoodsafety.com",
        WEBSOCKET: "wss://okh6i0qqgi.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_2hHiDIs9H",
        IDENTITY_POOL_ID: "ap-southeast-2:cf2b3b5b-24f1-49d5-8ca0-e6e3039d0673",
        USERPOOL_HOSTED_DOMAIN: "forwood-newcrest-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.newcrest.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.newcrest.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::423056432441:role/prod-CA"
    },
    samlProvider: {
      NAME: 'newcrest',
      SUPPORTED_LIST: 'newcrest,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.5",
        HOSTNAME: "id.newcrest.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".newcrest.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "44302b9b-5168-4406-9c98-35b636528121",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.newcrest.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
